import { useState } from 'react';

import JobsAppBanner from './components/JobsAppBanner';
import JobsAppHeader from './components/JobsAppHeader';
import JobsAppSubFooter from './components/JobsAppSubFooter';
import SideNav from './components/SideNav';
import EarlyCareersPage from './pages/EarlyCareersPage';
import HomePage from './pages/HomePage';
import HowToApplyPage from './pages/HowToApplyPage';
import JobPage from './pages/JobPage';
import JobsPage from './pages/JobsPage';
import LifePage from './pages/LifePage';
import CandidatePrepPack from './pages/candidatePrepPack/CandidatePrepPack';
import BarcelonaPage from './pages/offices/BarcelonaPage';
import EdinburghPage from './pages/offices/EdinburghPage';
import GlasgowPage from './pages/offices/GlasgowPage';
import LondonPage from './pages/offices/LondonPage';
import MiamiPage from './pages/offices/MiamiPage';
import OfficesPage from './pages/offices/OfficesPage';
import ShenzhenPage from './pages/offices/ShenzhenPage';
import SingaporePage from './pages/offices/SingaporePage';
import TokyoPage from './pages/offices/TokyoPage';
import BusinessPage from './pages/teams/BusinessPage';
import DesignPage from './pages/teams/DesignPage';
import EngineeringPage from './pages/teams/EngineeringPage';
import GraduatesPage from './pages/teams/GraduatesPage';
import InternshipPage from './pages/teams/InternshipPage';
import MarketingPage from './pages/teams/MarketingPage';
import ProductPage from './pages/teams/ProductPage';
import TeamsPage from './pages/teams/TeamsPage';

import type { JobsAppProps } from '@skyscanner-internal/falcon-shared-types/types/components';

import STYLES from './JobsApp.module.scss';

const JobsApp = ({ jobsSlug }: JobsAppProps) => {
  const [showSideMenu, setShowSideMenu] = useState(false);

  const toggleSideMenu = () => {
    setShowSideMenu(!showSideMenu);
  };
  const renderSelectedJobsPage = () => {
    if (jobsSlug === undefined) {
      return <HomePage />;
    }
    if (jobsSlug?.startsWith('job')) {
      return <JobPage jobsSlug={jobsSlug} />;
    }
    switch (jobsSlug) {
      case 'current-jobs':
        return <JobsPage />;
      case 'offices':
        return <OfficesPage />;
      case 'barcelona':
        return <BarcelonaPage />;
      case 'edinburgh':
        return <EdinburghPage />;
      case 'glasgow':
        return <GlasgowPage />;
      case 'london':
        return <LondonPage />;
      case 'miami':
        return <MiamiPage />;
      case 'shenzhen':
        return <ShenzhenPage />;
      case 'singapore':
        return <SingaporePage />;
      case 'tokyo':
        return <TokyoPage />;
      case 'life':
        return <LifePage />;
      case 'teams':
        return <TeamsPage />;
      case 'business':
        return <BusinessPage />;
      case 'design':
        return <DesignPage />;
      case 'engineering':
        return <EngineeringPage />;
      case 'product':
        return <ProductPage />;
      case 'marketing':
        return <MarketingPage />;
      case 'earlycareers':
        return <EarlyCareersPage />;
      case 'earlycareers/graduates':
        return <GraduatesPage />;
      case 'earlycareers/intern':
        return <InternshipPage />;
      case 'earlycareers/how-to-apply':
        return <HowToApplyPage />;
      default:
        return <HomePage />;
    }
  };

  const renderCandidatePrepPack = jobsSlug === 'prep-pack';

  return (
    <div className={STYLES.JobsApp}>
      <script src="https://boards.greenhouse.io/embed/job_board/js?for=skyscanner" />
      <div className="jobssite-body">
        <SideNav showSideMenu={showSideMenu} toggleSideMenu={toggleSideMenu} />
        <div className="main-content grid__container--full-width">
          {renderCandidatePrepPack ? (
            <CandidatePrepPack />
          ) : (
            <>
              <JobsAppHeader
                jobsSlug={jobsSlug}
                toggleSideMenu={toggleSideMenu}
              />
              <JobsAppBanner />
              {renderSelectedJobsPage()}
              <JobsAppSubFooter />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobsApp;
