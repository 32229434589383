import { useState } from 'react';

import DropdownItem from './DropdownItem';
import {
  InterviewProcessModal,
  ModalType,
  type ModalTypeKeys,
} from './InterviewProcessModal';
import {
  CultureInterviewStage,
  DepthOfExpertiseInterviewStage,
  FinalStageHeader,
  FirstStageHeader,
  InterviewNote,
  InterviewPrepButton,
  SecondStageHeader,
  SystemDesignInterviewStage,
  TechnicalChallengeInterviewStage,
} from './InterviewProcessStages';

const InterviewProcess = () => {
  const [modalType, setModalType] = useState<ModalTypeKeys>(ModalType.Closed);

  const handleChangeModalType = (newModalType: ModalTypeKeys) => {
    if (modalType === newModalType) {
      setModalType(ModalType.Closed);
    } else {
      setModalType(newModalType);
    }
  };

  return (
    <div id="interview-modal-container">
      <InterviewProcessModal
        modalType={modalType}
        handleSetModalType={handleChangeModalType}
      />
      <section className="interview-process-section section-padding">
        <div className="row" style={{ textAlign: 'left' }}>
          <h2>Interview Process</h2>
        </div>
        <div className="dropdown-list row">
          <GraduateEngineerDropdownContainer
            onSetModalType={handleChangeModalType}
          />
          <EngineeringDropdownContainer
            title="Software Engineers"
            onSetModalType={handleChangeModalType}
          />
          <EngineeringDropdownContainer
            title="Data Engineers"
            onSetModalType={handleChangeModalType}
          />
          <EngineeringDropdownContainer
            title="Mobile Engineers"
            onSetModalType={handleChangeModalType}
          />
          <DataScientistDropdownContainer
            onSetModalType={handleChangeModalType}
          />
          <PrincipalEngineerDropdownContainer
            onSetModalType={handleChangeModalType}
          />
          <EngineeringManagerDropdownContainer
            onSetModalType={handleChangeModalType}
          />
        </div>
      </section>
    </div>
  );
};

interface DropdownContainerProps {
  onSetModalType: (newModalType: ModalTypeKeys) => void;
}

const GraduateEngineerDropdownContainer = ({
  onSetModalType,
}: DropdownContainerProps) => (
  <DropdownItem title="Graduate Engineers">
    <div className="interview_stages">
      <div className="interview_stage">
        <p className="title">Technical Challenge</p>
        <p className="body">
          An online technical test. It should take about two hours.
        </p>
      </div>
      <FirstStageHeader />
      <div className="interview_stage">
        <p className="title">Call with recruiter</p>
        <p className="body">
          An initial call to discuss your experience and this role in more
          detail.
        </p>
      </div>
      <div className="interview_stage">
        <hr />
        <p className="title uppercase">Final Stage</p>
        <p className="title">Discovery Day</p>
        <br />
        <p className="body">
          This is an all day session, split into a number of different
          activities and interviews (with breaks). It will give you the chance
          to meet some of the team, have a look around the office and ask any
          questions you have about the role or working at Skyscanner.
        </p>
        <br />
        <p className="body">
          The morning session consists of a 90 minute group exercise followed by
          a short presentation and Q&amp;A.
        </p>
        <br />
        <p className="body">
          In the afternoon, you&apos;ll have two individual interviews. One will
          be a cultural interview similar to the video call you did at the
          previous stage of the process. The other will be more focused on the
          role you&apos;ve applied for and will be conducted by one of our
          engineers.
        </p>
      </div>

      <button
        className="interview-stage-btn"
        onClick={() => onSetModalType(ModalType.Graduate)}
        type="button"
      >
        Prep for the Discovery Day
      </button>

      <hr />
      <InterviewNote />
    </div>
  </DropdownItem>
);

interface EngineeringDropdownContainerProps extends DropdownContainerProps {
  title: string;
}

const EngineeringDropdownContainer = ({
  onSetModalType,
  title,
}: EngineeringDropdownContainerProps) => (
  <DropdownItem title={title}>
    <div className="interview_stages">
      <FirstStageHeader entryMid />
      <TechnicalChallengeInterviewStage />
      <SecondStageHeader />
      <DepthOfExpertiseInterviewStage />
      <InterviewPrepButton
        stage="2"
        modalType={ModalType.Engineer2}
        onSetModalType={onSetModalType}
      />
      <FinalStageHeader />
      <CultureInterviewStage />
      <SystemDesignInterviewStage />
      <InterviewPrepButton
        stage="Final"
        modalType={ModalType.EngineerFinal}
        onSetModalType={onSetModalType}
      />
      <hr />
      <InterviewNote />
    </div>
  </DropdownItem>
);

const DataScientistDropdownContainer = ({
  onSetModalType,
}: DropdownContainerProps) => (
  <DropdownItem title="Data Scientists">
    <div className="interview_stages">
      <FirstStageHeader entryMid />
      <TechnicalChallengeInterviewStage />
      <SecondStageHeader />
      <div className="interview_stage">
        <p className="title">Project discussion and case study</p>
        <p className="body">
          A technical conversation, focused on a project you&apos;re able to
          talk about in depth that demonstrates a good understanding of your
          work.
        </p>
        <br />
        <p className="body">
          This is followed by a scenario-based exercise which could cover
          analytics or experimentation.
        </p>
      </div>
      <InterviewPrepButton
        stage="2"
        modalType={ModalType.DataScientist2}
        onSetModalType={onSetModalType}
      />
      <FinalStageHeader />
      <div className="interview_stage">
        <p className="title">Final Stage Technical Interview</p>
        <p className="body">
          An interactive technical problem-solving interview, looking at a
          real-world problem that could be found at Skyscanner, how you would
          solve it and put the solution into production.
        </p>
      </div>
      <CultureInterviewStage />
      <InterviewPrepButton
        stage="Final"
        modalType={ModalType.DataScientistFinal}
        onSetModalType={onSetModalType}
      />
      <hr />
      <InterviewNote />
    </div>
  </DropdownItem>
);

const PrincipalEngineerDropdownContainer = ({
  onSetModalType,
}: DropdownContainerProps) => (
  <DropdownItem title="Principal Engineers">
    <div className="interview_stages">
      <FirstStageHeader />
      <DepthOfExpertiseInterviewStage />
      <InterviewPrepButton
        stage="1"
        modalType={ModalType.PrincipalEngineer1}
        onSetModalType={onSetModalType}
      />
      <FinalStageHeader />
      <SystemDesignInterviewStage double />
      <CultureInterviewStage />
      <InterviewPrepButton
        stage="Final"
        modalType={ModalType.PrincipalEngineerFinal}
        onSetModalType={onSetModalType}
      />
      <hr />
      <InterviewNote />
    </div>
  </DropdownItem>
);

const EngineeringManagerDropdownContainer = ({
  onSetModalType,
}: DropdownContainerProps) => (
  <DropdownItem title="Engineering Managers">
    <div className="interview_stages">
      <FirstStageHeader />
      <div className="interview_stage">
        <p className="title">Managing People</p>
        <p className="body">
          This interview will explore your depth of management experience and
          look to gain an understanding of your previous successes and
          challenges as a manager.
        </p>
        <p className="body">
          The interviewer will be interested to hear about the size of teams
          you&rsquo;ve managed and how your style has evolved over time; think
          both sides of the management spectrum, from managing underperformers
          to ensuring high flyers are continuously growing through being
          challenged.
        </p>
      </div>
      <FinalStageHeader />
      <CultureInterviewStage />
      <SystemDesignInterviewStage />
      <div className="interview_stage">
        <p className="title">Team Delivery and Development</p>
        <p className="body">
          The Delivery and Team Development interview begins with a short case
          study. The opening situation is given ahead of time for you to think
          about and new information will be given during the session. During the
          interview you&apos;ll talk through the actions, tools and frameworks
          that you would apply to the scenario and rationale behind your
          choices.
        </p>
      </div>
      <InterviewPrepButton
        stage="Final"
        modalType={ModalType.EngineeringManagerFinal}
        onSetModalType={onSetModalType}
      />
      <hr />
      <InterviewNote />
    </div>
  </DropdownItem>
);

export default InterviewProcess;
