import { useState } from 'react';

import loadable from '@loadable/component';

const IntersectionObserverWrapper = loadable(
  () =>
    import('../../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const HowWeMove = () => {
  const [showContent, setShowContent] = useState(false);

  return (
    <IntersectionObserverWrapper
      threshold={0.1}
      onElementSeen={() => setShowContent(true)}
    >
      <HowWeMoveContent showContent={showContent} />
    </IntersectionObserverWrapper>
  );
};

export default HowWeMove;

export const HowWeMoveContent = ({ showContent }: { showContent: boolean }) => (
  <div className="how-we-move-container">
    <div className="how-we-move-image" />
    <div className="how-we-move-lower">
      <div className="how-we-move-content">
        <div className="how-we-move-content-left">
          <h2
            className={`how-we-move-title ${
              showContent ? `fade-in` : `hidden`
            }`}
          >
            How we move
          </h2>
          <p className={showContent ? `fade-in-delay-1` : `hidden`}>
            We iterate, we improve, we measure and, where necessary, we change
            course. It&rsquo;s all part of being agile. When it comes to
            Engineering at Skyscanner, our teams strive to deliver an unmatched
            traveller experience. To help them do this, they&rsquo;re guided
            each day by our Engineering Principles.
          </p>
        </div>
        <div className="how-we-move-content-right">
          <ul className="how-we-move-list">
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-2` : `hidden`
              }`}
            >
              We have a clear definition of success for every piece of work
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-225` : `hidden`
              }`}
            >
              We ship multiple times a day and deliver customer value week in,
              week out
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-25` : `hidden`
              }`}
            >
              We use design reviews to validate every significant change
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-275` : `hidden`
              }`}
            >
              We deliver our products using our defined production standard
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-3` : `hidden`
              }`}
            >
              We peer review every change
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-325` : `hidden`
              }`}
            >
              We cover all changes with automated tests, responsibly
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-35` : `hidden`
              }`}
            >
              Our definitions of done include being live in production...
              responsibly
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-375` : `hidden`
              }`}
            >
              You build it, you run it
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-4` : `hidden`
              }`}
            >
              We own and are responsible for the data we produce
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-425` : `hidden`
              }`}
            >
              We write with inclusivity, diversity and accessibility in mind
            </li>
            <li
              className={`how-we-move-list-item ${
                showContent ? `fade-in-top-delay-45` : `hidden`
              }`}
            >
              <a
                className="underline"
                target="_blank"
                href="https://medium.com/@SkyscannerEng"
                rel="noreferrer"
              >
                Learn more about Engineering at Skyscanner here
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);
