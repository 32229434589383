import type { ModalTypeKeys } from './InterviewProcessModal';

export const FirstStageHeader = ({
  entryMid = false,
}: {
  entryMid?: boolean;
}) => (
  <div className="interview_stage">
    <hr />
    <p className="title uppercase">
      1<sup>st</sup> Stage {entryMid && '(Entry to Mid Level Only)'}
    </p>
    <p className="body">via Zoom</p>
  </div>
);

export const SecondStageHeader = () => (
  <div className="interview_stage">
    <hr />
    <p className="title uppercase">
      2<sup>nd</sup> Stage
    </p>
    <p className="body">via Zoom</p>
  </div>
);

export const FinalStageHeader = () => (
  <div className="interview_stage">
    <hr />
    <p className="title uppercase">Final Stage</p>
    <p className="body">Face-to-face</p>
  </div>
);

export const TechnicalChallengeInterviewStage = () => (
  <div className="interview_stage">
    <p className="title">Technical Challenge</p>
    <p className="body">
      A 45 minute session with one of our engineers to solve a basic flight
      search problem, based on one of our company benefits where all employees
      at Skyscanner get £300 to spend on travel. Write some code, talk through
      your idea and have a chance to ask the engineer anything you want about
      Skyscanner life or how we work.
    </p>
  </div>
);

export const DepthOfExpertiseInterviewStage = () => (
  <div className="interview_stage">
    <p className="title">Depth of Expertise</p>
    <p className="body">
      A technical conversation, focused on a project you enjoy talking about in
      depth to demonstrate a good example of your work.
    </p>
  </div>
);

export const CultureInterviewStage = () => (
  <div className="interview_stage">
    <p className="title">Culture</p>
    <p className="body">
      A competency-based, non-technical interview exploring how you approach
      your work in line with core Skyscanner values, as well as your motivations
      and drivers.
    </p>
  </div>
);

export const SystemDesignInterviewStage = ({
  double = false,
}: {
  double?: boolean;
}) => (
  <div className="interview_stage">
    <p className="title">System Design{double ? ' (x2)' : ''}</p>
    <p className="body">
      A whiteboarding system design interview, looking at a real-world problem
      that can be found at Skyscanner. This looks at how you would solve the
      problem, but also how you would get that solution into production.{' '}
      {double ? 'Each ' : 'This interview '} lasts 45-60 mins.
    </p>
  </div>
);

export const InterviewPrepButton = ({
  modalType,
  onSetModalType,
  stage,
}: {
  modalType: ModalTypeKeys;
  onSetModalType: (newModalType: ModalTypeKeys) => void;
  stage: '1' | '2' | 'Final';
}) => (
  <button
    className="interview-stage-btn"
    onClick={() => onSetModalType(modalType)}
    type="button"
  >
    Prep for your {stage}
    {stage === '1' && <sup>st</sup>}
    {stage === '2' && <sup>nd</sup>} Stage Interview
  </button>
);

export const InterviewNote = () => (
  <p className="interview-note">
    *If you have a disability or require any special arrangements at any stage
    of our interview process, then please let us know as soon as you can.
  </p>
);
